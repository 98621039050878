
.chat{position:fixed;right:0;top:0;bottom:0;height:100%;width:465px;z-index:999999;padding-right:32px;-webkit-box-sizing:border-box !important;box-sizing:border-box !important;font-family:"Squares", Arial, sans-serif;overflow:hidden;pointer-events:none;display:block
}
@media (max-width: 767px){
.chat{display:none
}
}
.chat *{-webkit-box-sizing:border-box !important;box-sizing:border-box !important;outline:none
}
@media (max-width: 1279px){
.chat{padding-right:16px;padding-bottom:16px
}
}
.chat__bg{position:absolute;height:100%;width:100%;left:0;bottom:0;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:end;-ms-flex-align:end;align-items:flex-end;z-index:1;overflow:hidden;pointer-events:none;width:480px;height:1000px
}
.chat__bg-video{margin-top:auto;-webkit-transform:translateY(60px);transform:translateY(60px);max-height:none;max-width:none;-o-object-fit:cover;object-fit:cover;-o-object-position:left bottom;object-position:left bottom;width:100%;height:100%
}
.chat__bg-video--hide{display:none
}
.chat__canvas{margin-top:auto;-webkit-transform:translateY(60px);transform:translateY(60px);max-height:none;max-width:none;-o-object-fit:cover;object-fit:cover;-o-object-position:left bottom;object-position:left bottom;width:100%;height:100%
}
.chat__canvas--hide{display:none
}
.chat__inner{z-index:2;position:relative;height:100%;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;-webkit-box-pack:end;-ms-flex-pack:end;justify-content:flex-end;padding-bottom:240px;max-height:100%
}
.chat__modal{background-color:#fff;padding:24px;padding-top:32px;position:relative;border-radius:8px;display:block;pointer-events:auto;-webkit-box-shadow:0px 4px 16px rgba(0,0,0,0.08);box-shadow:0px 4px 16px rgba(0,0,0,0.08);margin-left:16px
}
.chat__modal-close{font-size:12px;color:silver;position:absolute;top:8px;right:24px;-webkit-transition:0.3s;transition:0.3s
}
.chat__modal-close:hover{text-decoration:none;color:#24a762
}
.chat__modal-mute{width:24px;height:24px;color:#000;display:block;position:absolute;top:8px;left:8px;-webkit-transition:0.3s;transition:0.3s
}
.chat__modal-mute:hover{text-decoration:none;opacity:.8
}
.chat__modal-tabs{display:-webkit-box;display:-ms-flexbox;display:flex;margin-bottom:24px;border:1px solid #24a762;border-radius:8px;overflow:hidden
}
.chat__modal-tab{-webkit-box-flex:1;-ms-flex-positive:1;flex-grow:1;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;background:none;color:#24a762;-webkit-box-sizing:border-box;box-sizing:border-box;border:0px;padding:8px;font-size:14px;line-height:1.5;-webkit-transition:0.3s;transition:0.3s;cursor:pointer
}
.chat__modal-tab:hover{background-color:rgba(36,167,98,0.1)
}
.chat__modal-tab--active{background-color:#24a762;color:#fff
}
.chat__modal-tab--active:hover{background-color:#24a762;color:#fff
}
.chat__modal-title{font-weight:600;font-size:20px;text-align:center;margin-bottom:16px
}
@media (max-height: 770px){
.chat__modal-list{overflow-y:auto;max-height:300px;padding-right:24px
}
.chat__modal-list::-webkit-scrollbar-track{background-color:rgba(192,192,192,0.2);-webkit-box-shadow:inset 0 0 6px rgba(0,0,0,0.3);box-shadow:inset 0 0 6px rgba(0,0,0,0.3)
}
.chat__modal-list::-webkit-scrollbar{width:6px;background-color:rgba(192,192,192,0.2)
}
.chat__modal-list::-webkit-scrollbar-thumb{background-color:#24a762
}
}
.chat__modal-item{display:-webkit-box;display:-ms-flexbox;display:flex;border:1px solid silver;-webkit-box-sizing:border-box;box-sizing:border-box;border-radius:8px;color:inherit;text-decoration:none;padding:8px;font-size:14px;line-height:1.5;margin-bottom:8px;-webkit-transition:0.3s;transition:0.3s
}
.chat__modal-item:last-child{margin-bottom:0px
}
.chat__modal-item:hover{color:inherit;text-decoration:none;background-color:rgba(192,192,192,0.2)
}
.chat__modal-text{display:block
}
.chat__link-prev{background-color:silver;border-radius:8px;margin-top:16px;padding:8px;font-size:14px;line-height:1.5;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;color:#fff;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;text-decoration:none;-webkit-transition:0.3s;transition:0.3s;display:-webkit-box;display:-ms-flexbox;display:flex
}
.chat__link-prev:hover{text-decoration:none
}
.chat__link-prev:hover,.chat__link-prev:focus,.chat__link-prev:active{background-color:#219a5b
}
.chat__facts{pointer-events:auto;position:absolute;right:16px;bottom:16px;left:16px
}
.chat__toggle{position:absolute;bottom:32px;right:32px;color:#fff;width:64px;height:64px;border-radius:50%;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;background-color:#24a762;pointer-events:auto;border:0px;-webkit-box-shadow:0px 4px 16px rgba(0,0,0,0.08);box-shadow:0px 4px 16px rgba(0,0,0,0.08);-webkit-transition:0.3s;transition:0.3s;cursor:pointer
}
.chat__toggle svg{fill:currentColor
}
.chat__toggle:hover,.chat__toggle:focus,.chat__toggle:active{background-color:#219a5b
}
.chat .slide-fade-enter-active{-webkit-transition:all 0.8s ease;transition:all 0.8s ease
}
.chat .slide-fade-leave-active{-webkit-transition:all 0.4s ease;transition:all 0.4s ease
}
.chat .slide-fade-enter,.chat .slide-fade-leave-to{-webkit-transform:translateX(200%);transform:translateX(200%);opacity:0
}

.facts{background:#fff;-webkit-box-sizing:border-box;box-sizing:border-box;-webkit-box-shadow:0px 4px 16px rgba(0,0,0,0.08);box-shadow:0px 4px 16px rgba(0,0,0,0.08);border-radius:8px
}
.facts *{outline:none
}
.facts__header{padding:24px;padding-bottom:16px;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between;padding-top:32px;position:relative
}
.facts__close{font-size:12px;color:silver;position:absolute;top:8px;right:24px;-webkit-transition:0.3s;transition:0.3s
}
.facts__close:hover{text-decoration:none;color:#24a762
}
.facts__main{padding-left:24px;padding-right:24px;padding-bottom:16px
}
.facts__title{font-weight:600;font-size:12px;line-height:24px;letter-spacing:0.04em;text-transform:uppercase
}
.facts__navs{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center
}
.facts__count{margin-left:8px;margin-right:8px;font-weight:600;font-size:12px;line-height:12px;letter-spacing:0.04em;text-transform:uppercase
}
.facts__count span{color:#24a762
}
.facts__nav{color:#000;-webkit-transition:0.3s;transition:0.3s;cursor:pointer;border:0px;padding:0px;background:none;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;width:24px;height:24px;transition:0.3s
}
.facts__nav:hover{color:#24a762
}
.facts__nav svg{fill:currentColor;display:block
}
.facts__nav--next svg{-webkit-transform:rotate(180deg);transform:rotate(180deg)
}
.facts__list{overflow:hidden;min-height:90px
}
.facts__open-chat{background:#24a762;border-radius:0px 0px 8px 8px;font-weight:600;font-size:12px;line-height:24px;letter-spacing:0.04em;text-transform:uppercase;color:#fff;height:56px;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between;width:100%;border:0px;padding-left:24px;padding-right:24px;padding-top:8px;padding-bottom:8px;cursor:pointer;-webkit-transition:0.3s;transition:0.3s
}
.facts__open-chat svg{fill:currentColor
}
.facts__open-chat:hover,.facts__open-chat:focus,.facts__open-chat:active{background-color:#219a5b
}
.facts .slide-fact-enter-active,.facts .slide-fact-leave-active{-webkit-transition:all 0.3s ease;transition:all 0.3s ease
}
.facts .slide-fact-enter{-webkit-transform:translateX(200%);transform:translateX(200%);opacity:0
}
.facts .slide-fact-leave-to{-webkit-transform:translateX(-200%);transform:translateX(-200%);opacity:0
}

.form__item+.form__item{margin-top:8px
}
.form__control{background:#fff;border:1px solid silver;-webkit-box-sizing:border-box;box-sizing:border-box;border-radius:8px;padding:8px 16px;font-weight:normal;font-size:16px;line-height:24px;color:#000;width:100%;font-family:inherit
}
.form__submit{background-color:#24a762;border-radius:8px;margin-top:16px;padding:8px;font-size:14px;line-height:1.5;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;color:#fff;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;text-decoration:none;-webkit-transition:0.3s;transition:0.3s;display:-webkit-box;display:-ms-flexbox;display:flex;border:0px;display:block;width:100%;cursor:pointer
}
.form__submit:hover,.form__submit:focus,.form__submit:active{background-color:#219a5b
}
